<template>
  <div></div>
</template>

<script>
export default {
  name: 'Error404',
  created() {
    this.$router.push('/')
  },
}
</script>
